<template>
  <div class="/examples">
  <ExamplesBar/>
  <Verge3D />
  </div>
</template>

<script>
// import { LazyYoutube } from "vue-lazytube";
// import ExAnimations from '@/components/ExAnimations.vue';
// import Armory3D from '@/components/examples_vue/Armory3D.vue';
import Verge3D from '@/components/examples_vue/Verge3D.vue';
// import ExAnimations from '@/components/ExAnimations.vue';
import ExamplesBar from '@/components/controls_vue/ExamplesBar.vue';
export default {
  name: "Examples",
  components: {
    // ExAnimations,
    // Armory3D,
    Verge3D,
    ExamplesBar,
  },
};
</script>
<style scoped>
#tabb {
	/* position: bottom; */
	/* padding: 20px; */
	color: #265787;
  /* color: cyan;   */
	opacity: 1;
	transition: opacity 0.6s;
	/* left: 0px;
	right: 0px;
	bottom: 25%; */
	font-family: "ToxicPowers";
	/* text-align: center;
	font-size: 32px; */
}

@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
